import isMobile from "../common/is-mobile.js";
import jScrollPane from "../../plugins/jquery_jscrollpane.min.js";

if (isMobile(820)) {
  $(".js-scroll-panel").jScrollPane({
    showArrows: true,
    autoReinitialise: true,
    hideFocus: true
  });
}
