/*
* 画像保存禁止
* @return none
*/
function imgGuard(name){
  //PCの右クリックによる画像保存禁止処理
  $(name).on('contextmenu',function(e){
    return false;
  });
  $(name).on('mousedown mouseup',function(e){
    return false;
  });
  //スマホ長押しによる画像保存禁止処理
  $(name).css({
    'pointer-events':'none',//iphone対応するための記述
    '-webkit-touch-callout':'none',
    '-webkit-user-select':'none',
    '-moz-touch-callout':'none',
    '-moz-user-select':'none',
    'touch-callout':'none',
    'user-select':'none'
  });
}


var selector = [
  '.photo-layout',//写真詳細
  '.articleDetail-content'//記事詳細
]

//特定のclassがあれば右クリック禁止
selector.map(function(v) {
  if($(v).length) {
    imgGuard('img');
  }
});
