import addEventListenerMultiType from '../common/multiEventLister.js';
const stepBorder = document.querySelector('.oem-flow-step-border')
const uaIE = document.querySelector('.ua-ie')

if(stepBorder) {
  addEventListenerMultiType(window,'load resize', () => {
    const flowStart = document.querySelector('.is-flow-return-start').getBoundingClientRect();
    const flowEnd = document.querySelector('.is-flow-return-end').getBoundingClientRect();
    let flowStartTop = flowStart.top ;
    let flowEndTop = flowEnd.top ;
    const borderHeight = flowStartTop - flowEndTop;
    stepBorder.style.height = `${borderHeight}px`;
    if(uaIE) {
      const borderHeight = flowStartTop - flowEndTop - 8;
      stepBorder.style.height = `${borderHeight}px`;
    }
  })
};