import swiper from 'swiper';
import isMobile from '../common/is-mobile.js';
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";

const autoplay = 4000;
const tl = new TimelineMax();

var topHero = new swiper('.js-top-hero-slider', {
  effect: "fade",
  speed: 1000,
  autoplay: {
    delay: autoplay,
    disableOnInteraction: false,
  },
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  },
  on: {
    init: function () {
      $(".top-hero-slider .swiper-slide[data-swiper-slide-index=" + (this.realIndex) + "]").addClass('is-active');
    }
  }
});

// class zoomAnimation {
//   constructor() {
//     this.target = document.querySelector('.swiper-slide-active');
//   }
// }
topHero.on('slideChangeTransitionStart', function(){
  // console.log(topHero.realIndex);
  $(".top-hero-slider .swiper-slide[data-swiper-slide-index=" + (topHero.realIndex) + "]").addClass('is-active');
});
topHero.on('slideChangeTransitionEnd', function(){
  // console.log(topHero.realIndex);
  $(".top-hero-slider .swiper-slide").removeClass('is-active');
  $(".top-hero-slider .swiper-slide[data-swiper-slide-index=" + (topHero.realIndex) + "]").addClass('is-active');

});
// function attachEvent() {
//   // tl.to('.swiper-slide-active', 40, { scale: 2 });
//   console.log(topHero.activeIndex);
// }

//スマホで画面の高さ取得・設定
$(window).on('resize', function(){
  if(isMobile(768)) {
    $('.top-hero').css({
      'height': $(window).height() - 210
    });
  } else {
    $('.top-hero').removeAttr('style');
  }
}).trigger('resize');
